export default [
    'AB - Alberta',
    'BC - British Columbia',
    'MB - Manitoba',
    'NB - New Brunswick',
    'NL - Newfoundland',
    'NS - Nova Scotia',
    'NT - Northwest Territories',
    'NU - Nunavut',
    'ON - Ontario',
    'PE - Prince Edward Island',
    'QC - Quebec',
    'SK - Saskatchewan',
    'YT - Yukon'
];