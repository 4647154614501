<template>
  <div class="container box">
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>

    <div v-if="!loading">
      <p class="title">{{ convention.name }}</p>

      <section class="box has-background-info-light">
        <p class="title is-4 has-text-info-dark">Summary</p>
        <div class="is-flex is-flex-wrap-wrap">
          <div
            v-for="item in eventSummaryItems"
            :key="item.description"
            class="card summary-card"
          >
            <div class="card-content">
              <p class="has-text-centered has-text-info-dark summary-value">
                {{ item.value }}
              </p>
              <p class="is-flex is-justify-content-center">
                <b-icon :icon="item.icon" />
              </p>
              <p class="has-text-centered summary-description">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <div class="buttons is-right">
        <b-button
          class="button"
          icon-left="refresh"
          type="is-info is-light"
          outlined
          :loading="refreshing"
          @click.prevent="handleRefresh"
          >Check for Updates</b-button
        >
        <b-button
          class="button"
          icon-left="download"
          type="is-info is-light"
          outlined
          :loading="exporting"
          @click.prevent="handleExport"
          >Export Registrations</b-button
        >
      </div>

      <b-tabs type="is-boxed">
        <b-tab-item label="Attendees" icon="account-group">
          <admin-event-attendees :registrations="attendees" />
        </b-tab-item>
        <b-tab-item label="Volunteers" icon="clipboard-account">
          <admin-event-volunteers :registrations="volunteers" />
        </b-tab-item>
        <b-tab-item v-if="convention.discountCodes" label="Discount Codes" icon="cash">
          <admin-event-discount-codes :convention="convention" />
        </b-tab-item>
        <b-tab-item label="Settings" icon="cog">
          <admin-event-settings :convention="convention" />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import mixins from "@/components/mixins";

// // @ is an alias to /src
import { logError } from "@/utils/logger";
import { formatCurrency } from "@/utils/number";
import { formatTimestamp } from "@/utils/dateandtime";
import actions from "@/utils/constants/action_types";
import AdminEventAttendees from "./AdminEventAttendees.vue";
import AdminEventVolunteers from "./AdminEventVolunteers.vue";
import AdminEventDiscountCodes from "./AdminEventDiscountCodes.vue";
import AdminEventSettings from "./AdminEventSettings.vue";

export default {
  name: "AdminEventDetails",

  mixins: [mixins],

  components: {
    AdminEventAttendees,
    AdminEventVolunteers,
    AdminEventDiscountCodes,
    AdminEventSettings,
  },

  data() {
    return {
      loading: true,
      exporting: false,
      refreshing: false,
    };
  },

  computed: {
    ...mapState({
      registrations: (state) => state.admin.registrations,
      conventions: (state) => state.admin.conventions,
    }),

    convention() {
      return this.conventions.find((item) => item.id === this.conventionId);
    },

    conventionId() {
      return this.$route.params.eventId;
    },

    attendees() {
      return this.registrations[this.conventionId]?.attendees;
    },

    volunteers() {
      return this.registrations[this.conventionId]?.volunteers;
    },

    eventSummaryItems() {
      const payments = this.totalPayments || 0;
      const discounts = this.totalDiscounts || 0;
      const donations = this.totalDonations || 0;

      return [
        {
          description: "attendee registrations",
          value: this.attendees.length,
          icon: "text-box",
        },
        {
          description: "attendees",
          value: this.totalAttendeeCount,
          icon: "account-group",
        },
        {
          description: "attendee rooms",
          value: this.totalAttendeeRooms,
          icon: "bed",
        },
        {
          description: "attendee meals",
          value: this.totalAttendeeMeals,
          icon: "food",
        },
        {
          description: "volunteers",
          value: this.volunteers.length,
          icon: "clipboard-account",
        },
        {
          description: "total payments",
          value: formatCurrency(payments),
          icon: "cash-multiple",
        },
        {
          description: "total discounts",
          value: formatCurrency(discounts),
          icon: "cash-minus",
        },
        {
          description: "total donations",
          value: formatCurrency(donations),
          icon: "cash-plus",
        },
      ];
    },

    totalAttendeeRooms() {
      return this.attendees.reduce((sum, item) => sum + item.room.roomCount, 0);
    },

    totalAttendeeCount() {
      return this.attendees.reduce(
        (sum, item) => sum + item.familyMembers.length,
        0
      );
    },

    totalAttendeeMeals() {
      return this.attendees.reduce(
        (sum, item) =>
          sum + item.familyMembers.filter((m) => m.addMeals).length,
        0
      );
    },

    totalPayments() {
      const sumPayments = (sum, item) => {
        if (item.paymentInfo && item.paymentInfo.amount) {
          return sum + parseFloat(item.paymentInfo.amount);
        } else {
          return sum;
        }
      };

      const attendeePayments = this.attendees.reduce(sumPayments, 0);
      const volunteerPayments = this.volunteers.reduce(sumPayments, 0);
      return attendeePayments + volunteerPayments;
    },

    totalDiscounts() {
      const sumDiscounts = (sum, item) => {
        if (item.discountAmount) {
          return sum + item.discountAmount;
        } else {
          return sum;
        }
      };

      const attendeePayments = this.attendees.reduce(sumDiscounts, 0);
      const volunteerPayments = this.volunteers.reduce(sumDiscounts, 0);
      return attendeePayments + volunteerPayments;
    },

    totalDonations() {
      const sumDonations = (sum, item) => {
        if (item.donationAmount) {
          return sum + item.donationAmount;
        } else {
          return sum;
        }
      };

      const attendeeDonations = this.attendees.reduce(sumDonations, 0);
      const volunteerDonations = this.volunteers.reduce(sumDonations, 0);
      return attendeeDonations + volunteerDonations;
    },
  },

  methods: {
    ...mapActions("admin", [
      actions.admin.FETCH_ALL_CONVENTIONS,
      actions.admin.FETCH_REGISTRATIONS,
      actions.admin.EXPORT_REGISTRATIONS,
    ]),

    handleExport() {
      this.exporting = true;
      this[actions.admin.EXPORT_REGISTRATIONS]({
        conventionId: this.conventionId,
      })
        .then((response) => {
          const contentType = response.headers["content-type"];
          const fileName = `ARM-registrations-export_${formatTimestamp(
            new Date()
          )}.xlsx`;
          const downloadUrl = URL.createObjectURL(
            new Blob([response.data], {
              type: contentType,
            })
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          this.exporting = false;
        })
        .catch((err) => {
          const message = `Failed to export data. ${err?.message}`;
          logError(message, err);
          this.exporting = false;
          this.notifyError(message);
        });
    },

    handleRefresh() {
      this.refreshing = true;
      this[actions.admin.FETCH_REGISTRATIONS]({
        conventionId: this.conventionId,
      })
        .then(() => {
          this.refreshing = false;
        })
        .catch((err) => {
          const message = `Failed to refresh data. ${err?.message}`;
          logError(message, err);
          this.refreshing = false;
          this.notifyError(message);
        });
    },
  },

  mounted() {
    const dataPromises = [];

    if (!this.convention) {
      dataPromises.push(this[actions.admin.FETCH_ALL_CONVENTIONS]());
    }

    if (!this.registrations[this.conventionId]) {
      dataPromises.push(
        this[actions.admin.FETCH_REGISTRATIONS]({
          conventionId: this.conventionId,
        })
      );
    }

    if (dataPromises.length) {
      Promise.all(dataPromises)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          const message = `Failed to load data. ${err?.message}`;
          logError(message, err);
          this.loading = false;
          this.notifyError(message);
        });
    } else {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.summary-card {
  width: 130px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.summary-value {
  font-size: 1.5rem;
  font-weight: bold;
}
.summary-description {
  font-size: 0.82rem;
  font-weight: bold;
}
</style>