<template>
  <div>
    <header-view :selectedConvention="selectedConvention" />
    <div class="container">
      <section class="box m-3">
        <p
          class="
            title
            is-5
            has-background-success has-text-white
            p-2
            is-uppercase
          "
        >
          Confirmation
        </p>
        <b-message type="is-success" has-icon>
          Thank you for registering! You will receive a confirmation email
          shortly.
        </b-message>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import HeaderView from "@/components/HeaderView.vue";

export default {
  name: "ConfirmationPage",

  components: {
    HeaderView,
  },

  computed: {
    ...mapState({
      selectedConvention: (state) => state.global.selectedConvention,
    }),
  },
};
</script>