<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Family Member</p>
    </header>
    <section class="modal-card-body">
      <b-field label="Family Member Type">
        <b-select v-model="familyMemberType">
          <option
            v-for="option in memberTypeOptions"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </b-select>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Cancel" @click="$emit('close')" />
      <b-button label="Add" type="is-primary" @click="handleAdd" />
    </footer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import actions from "@/utils/constants/action_types";
import getMemberTypeLabel from "@/utils/getMemberTypeLabel";

const namespace = "attendee";
const scopedActions = actions[namespace];

export default {
  name: "AddFamilyMemberModal",

  data() {
    return {
      familyMemberType: null,
    };
  },

  computed: {
    ...mapState(namespace, ["familyMembers"]),

    memberTypeOptions() {
      const options = [];

      ["father", "mother"].forEach((type) => {
        if (!this.familyMembers.find((item) => item.type === type)) {
          options.push({ label: getMemberTypeLabel(type), value: type });
        }
      });

      ["angels", "otherAdults", "children"].forEach((type) => {
        options.push({ label: getMemberTypeLabel(type), value: type });
      });

      return options;
    },
  },

  mounted() {
    this.familyMemberType = this.memberTypeOptions[0].value;
  },

  methods: {
    ...mapActions(namespace, [scopedActions.ADD_FAMILY_MEMBER]),

    handleAdd() {
      this[scopedActions.ADD_FAMILY_MEMBER]({
        type: this.familyMemberType,
      });
      this.$emit("close");
    },
  },
};
</script>