import actions from "@/utils/constants/action_types";
import mutations from "@/utils/constants/mutation_types";
import { getConventionByUrlPath, getPriestNames } from '@/api';

const state = () => ({
    selectedConvention: null,
    rulesAccepted: false,
    covidDisclaimerAccepted: false,
    refData: {},
});

const getterHandlers = {};

const actionHandlers = {
    async [actions.FETCH_SELECTED_CONVENTION]({ commit }, payload) {
        const data = await getConventionByUrlPath(payload.urlPath);
        commit(mutations.SELECTED_CONVENTION_FETCHED, { conventionDetails: data });
    },

    async [actions.FETCH_PRIEST_NAMES]({ commit }) {
        const data = await getPriestNames();
        commit(mutations.PRIEST_NAMES_FETCHED, { priestNames: data });
    },

    [actions.ACCEPT_RULES]({ commit }) {
        commit(mutations.RULES_ACCEPTED);
    },

    [actions.ACCEPT_COVID_DISCLAIMER]({ commit }) {
        commit(mutations.COVID_DISCLAIMER_ACCEPTED);
    }
};

const mutationHandlers = {
    [mutations.SELECTED_CONVENTION_FETCHED](state, payload) {
        state.selectedConvention = payload.conventionDetails;
    },

    [mutations.PRIEST_NAMES_FETCHED](state, payload) {
        state.refData = { ...state.refData, priestNames: payload.priestNames };
    },

    [mutations.RULES_ACCEPTED](state) {
        state.rulesAccepted = true;
    },

    [mutations.COVID_DISCLAIMER_ACCEPTED](state) {
        state.covidDisclaimerAccepted = true;
    }
};

export default {
    state,
    getters: getterHandlers,
    actions: actionHandlers,
    mutations: mutationHandlers
}