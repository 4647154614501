const config = {
    fields: [
        {
            name: "father",
            type: "switch",
        },
        {
            name: "mother",
            type: "switch",
        },
        {
            name: "angels",
            label: "Angels (individuals with special needs)",
            type: "counter",
            min: 1,
        },
        {
            name: "otherAdults",
            type: "counter",
            min: 0,
        },
        {
            name: "children",
            label: "Other Children",
            type: "counter",
            min: 0,
        },
    ]
};

export default config;