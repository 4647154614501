export default [
    "USA - AL - Birmingham - St. John Kame",
    "USA - AL - Mobile",
    "USA - AZ - Northern Arizona - St. Makarious",
    "USA - AZ - Peoria - St. Mary",
    "USA - AZ - Phoenix - Archangel Michael",
    "USA - AZ - Scottsdale - St. Mark",
    "USA - AZ - SE Phoenix - St. Joseph the Carpenter",
    "USA - AZ - Tucson - St. John the Beloved",
    "USA - AR - Little Rock - St. George",
    "USA - CA - Anaheim - St. Mary and St. Verena",
    "USA - CA - Bakersfield - St. Demiana",
    "USA - CA - Bellflower - St. George",
    "USA - CA - Campbell - St. George",
    "USA - CA - Canyon Country - St. Mary of Egypt",
    "USA - CA - Cathedral City - St. Mary Magdalene",
    "USA - CA - Colton - St. Mina",
    "USA - CA - Concord - St. Mary and St. Mina",
    "USA - CA - Covina - St. John",
    "USA - CA - Fresno - Archangels Michael & Gabriel",
    "USA - CA - Hayward - St. Antonious",
    "USA - CA - Hayward - St. Mary Eritrean Orthodox Church",
    "USA - CA - Lake Forest - St. Marina",
    "USA - CA - Los Angeles - Holy Virgin Mary",
    "USA - CA - Los Angeles - St. Mark",
    "USA - CA - Modesto - St. Mark",
    "USA - CA - Monterey - St. Mark",
    "USA - CA - New Berryspring - St. Anthony's Monastery",
    "USA - CA - Norco - St. Antony & St. Abanoub",
    "USA - CA - Northridge - St. Mary & St. Athanasius",
    "USA - CA - Orange - St. Verena & The Three Holy Youth",
    "USA - CA - Oxnard - St. John the Baptist",
    "USA - CA - Palmdale - Archangel Raphael & St. Mina",
    "USA - CA - Pleasanton - St. Mary & St. John",
    "USA - CA - Pomona - St. Cyril",
    "USA - CA - Pomona - St. Maurice",
    "USA - CA - Rancho Cordova - St. Mary",
    "USA - CA - Rancho Cucamonga - St. Youstina",
    "USA - CA - Ripon - St. Mark",
    "USA - CA - San Diego - Holy Cross",
    "USA - CA - San Diego - St. Demiana",
    "USA - CA - Santa Ana - Archangel Michael",
    "USA - CA - Santa Monica - St. Peter and St. Paul",
    "USA - CA - Simi Valley - Archangel Michael",
    "USA - CA - Torrance - St. Mercurious and St. Abraam",
    "USA - CA - Victorville - St. Mary",
    "USA - CA - Visalia - St. George & St. Bishoy",
    "USA - CO - Englewood - St. Mark",
    "USA - CT - Hamden - Virgin Mary & Archangel Michael",
    "USA - FL - Clearwater - St. Mary & St. Mina",
    "USA - FL - Crystal River - St. Maximus & St. Domadius",
    "USA - FL - Daytona Beach - St. George",
    "USA - FL - Delray Beach - St. Mary",
    "USA - FL - Destin",
    "USA - FL - Fort Myers - St. Mark",
    "USA - FL - Gainesville - St. Mary Magdalene",
    "USA - FL - Jacksonville - St. Demiana",
    "USA - FL - Keys",
    "USA - FL - Largo - St. Shenouda the Archmandrite",
    "USA - FL - Maitland - St. Anthony",
    "USA - FL - Melbourne - Archangel Michael",
    "USA - FL - Miramar - St. John the Baptist",
    "USA - FL - New Port Richey - St. Verena",
    "USA - FL - Orlando - St. Athanasius",
    "USA - FL - Orlando - St. Cyril the Great",
    "USA - FL - Orlando - St. Peter",
    "USA - FL - Orlando - St. Rebekah",
    "USA - FL - Oviedo - St. Mary & Archangel Michael",
    "USA - FL - Palm Habor - St. Philopateer Mercurius",
    "USA - FL - Panama City",
    "USA - FL - Pensacola - St. Athanasius",
    "USA - FL - Sarasota - St. Simon the Tanner",
    "USA - FL - South Florida - St. Luke",
    "USA - FL - Tallahassee - St. Mary & St. George",
    "USA - FL - Tampa - St. George",
    "USA - FL - The Villages - St. Moses",
    "USA - FL - Vero Beach - St. Philopateer",
    "USA - FL - Wesley Chapel - St. Reweis",
    "USA - FL - West Palm Beach - St. Peter, Seal of the Martyrs",
    "USA - GA - Atlanta - Medhane Alem Eritriean Orthodox Church",
    "USA - GA - Atlanta - St. Mark",
    "USA - GA - Atlanta - St. Paul",
    "USA - GA - Macon - Archangel Michael",
    "USA - GA - Martinez - St. Augustine",
    "USA - GA - Roswell - St. Mary",
    "USA - GA - Savannah - St. Abanoub",
    "USA - GA - South Atlanta - St. Anthony the Great",
    "USA - HI - Honolulu - St. Mark",
    "USA - IL - Burr Ridge - St. Mark",
    "USA - IL - Chicago - St. Paul",
    "USA - IL - Monee - St. George",
    "USA - IL - Palatine - St. Mary",
    "USA - IN - Indianapolis - St. Mary & St. Mark",
    "USA - IA - Council Bluffs - St. Mary & St. George",
    "USA - IA - Urbandale - St. Mary",
    "USA - KS - St. Anthony",
    "USA - KS - Merriam - St. Mark",
    "USA - LA - Lafayette - St. Mary of Egypt",
    "USA - LA - New Orleans - St. Mark",
    "USA - LA - Shreveport - St. Barbara",
    "USA - ME - St. George",
    "USA - MD - Cabin John - St. George",
    "USA - MD - Elkton - St. Mary",
    "USA - MD - Savage - St. Mary",
    "USA - MA - Charlton - Holy Virgin Mary Spiritual Vineyard",
    "USA - MA - Natick - St. Mark",
    "USA - MI - Ann Arbor - St. Mary",
    "USA - MI - Grand Rapids - St. Mary & St. Mina",
    "USA - MI - Lansing",
    "USA - MI - Troy - St. Mark",
    "USA - MN - South St. Paul - St. Mary",
    "USA - MO - St. Louis - St. Mary & St. Abraam",
    "USA - NV - St. Athanasius",
    "USA - NV - St. Marina",
    "USA - NV - St. Peter & St. Paul",
    "USA - NV - Las Vegas - St. Mary",
    "USA - NJ - Bayonne - St. Abanoub & St. Antonious",
    "USA - NJ - Belleville - St. Mary & St. Mercurius",
    "USA - NJ - Cherry Hill - St. Anthony",
    "USA - NJ - East Brunswick - St. Mary",
    "USA - NJ - East Rutherford - St. Antonious & St. Mina",
    "USA - NJ - Elizabeth - St. Mary & St. Bishoy",
    "USA - NJ - Holmdel - St. Mina",
    "USA - NJ - Howell - Archangel Michael",
    "USA - NJ - Jersey City - St. George & St. Shenouda",
    "USA - NJ - Jersey City - St. Mark",
    "USA - NJ - McKee City - St. Paul",
    "USA - NJ - Medford - St Antony",
    "USA - NM - Albuquerque - St. Pishoy",
    "USA - NY - Albany - St. Mary & St. George",
    "USA - NY - Astoria - St. George",
    "USA - NY - Brooklyn - St. George",
    "USA - NY - New York - St. Mark",
    "USA - NY - New York City - St. Gabriel Eritrean Orthodox Church",
    "USA - NY - North White Plains - St. Mary & St. Dimiana",
    "USA - NY - Ridgewood - St. Mary & St. Antonios",
    "USA - NY - Spring Valley - Virgin Mary & St. Pachomious",
    "USA - NY - Staten Island - Archangel Michael & St. Mena",
    "USA - NY - Staten Island - St. Mary & St. George",
    "USA - NY - Syracuse - St. Mary & St. Mena",
    "USA - NY - West Henrietta - St. Mark",
    "USA - NY - Woodbury - St. Abraam",
    "USA - NC - Chapel Hill - Archangel Raphael & St. John the Beloved",
    "USA - NC - Charlotte - St. Mark",
    "USA - NC - Charlotte - St. Michael Eritrean Orthodox Tewahdo Church",
    "USA - NC - Raleigh - St. Mary",
    "USA - OH - Columbus - St. Mary",
    "USA - OH - Miamisburg - St. Mina & St. Abanoub",
    "USA - OH - Seven Hills - St. Mark",
    "USA - OH - Toledo - St. George",
    "USA - OK - Bixby - St. Peter & St. Paul",
    "USA - OK - Lawton - St. Samuel the Confessor",
    "USA - OK - Oklahoma City - St. Demiana",
    "USA - OR - Portland - St. Antonious",
    "USA - PA - Ambridge - St. Mary",
    "USA - PA - Annville - St. Anthony",
    "USA - PA - Harrisburg - St. Mark",
    "USA - PA - Hatfield - St. Mary and St. Kyrillos",
    "USA - PA - Norristown - St. George",
    "USA - PA - Silver Spring - St. Mary",
    "USA - RI - Cranston - St. Mary & St. Mena",
    "USA - SC - Fort Mill - St Mary & St Mina",
    "USA - SC - Greenville - St. Mary",
    "USA - SC - Myrtle Beach - St. Mark",
    "USA - TN - Chattanooga - St. Athanasius",
    "USA - TN - Clarksville - St. Abba Sarapamone",
    "USA - TN - Knoxville - St. Mary",
    "USA - TN - La Vergne - Archangel Michael",
    "USA - TN - Memphis - St. Mary & St. Rueis",
    "USA - TN - Nashville - St. George",
    "USA - TN - Nashville - St. Kyrillos the Sixth",
    "USA - TN - Nashville - St. Mark",
    "USA - TN - Nashville - St. Mary",
    "USA - TN - Nashville - St. Mina",
    "USA - TN - Nashville - St. Philopateer",
    "USA - TN - Nashville - St. Pishoy",
    "USA - TN - Nashville - St. Verena",
    "USA - TX - Arlington - St. George",
    "USA - TX - Austin - Holy Cross",
    "USA - TX - Bedford - Archangel Michael",
    "USA - TX - Bellaire - St. Mark",
    "USA - TX - College Station - St. Meena",
    "USA - TX - Colleyville - St. Mary",
    "USA - TX - Corpus Christi",
    "USA - TX - Dallas - St. Marina",
    "USA - TX - Dallas - St. Tekle Haimanot & St Aregawi Ethiopian Orthodox",
    "USA - TX - El Paso",
    "USA - TX - Euless - St. Abanoub",
    "USA - TX - Fort Worth - St. Meena",
    "USA - TX - Frisco - St. Mark",
    "USA - TX - Houston - Archangel Raphael",
    "USA - TX - Houston - St. Mary & Archangel Michael",
    "USA - TX - Houston - St. Paul",
    "USA - TX - Katy - St. George",
    "USA - TX - Lubbock - St. George",
    "USA - TX - Pearland - St. Catherine of Alexandria",
    "USA - TX - Richardson - St. Philopateer",
    "USA - TX - San Antonio - St. Anthony",
    "USA - TX - San Antonio - St. Demiana",
    "USA - VI - Bassterre St. Kitts - St. Mark & St. Bishoy",
    "USA - VI - St. Thomas - St. Mark",
    "USA - VT - Burlington - St. Mary & Archangel Raphael",
    "USA - VA - Arlington - St. Timothy & St. Athanasius",
    "USA - VA - Fairfax - St. Mark",
    "USA - VA - Moseley - Archangel Michael & St. Anthony",
    "USA - VA - Roanoke - St. Mary",
    "USA - WA - Bothell - St. George",
    "USA - WA - Lynnwood - St. Mary",
    "USA - WA - Puyallup - St. Mark",
    "USA - WA - Seattle - Eritrean Orthodox Church",
    "USA - WA - Spokane - St. Gregorios",
    "USA - WA - Spokane - St. Gregorios Malankara Orthodox Syrian Church",
    "USA - DC - Washington - Ethiopian Orthodox Tewahidedo Church",
    "USA - DC - Washington - Medhane Alem Eritrean Orthodox Church",
    "USA - WV - Huntington",
    "USA - WI - Madison - St. Mary & St. Rewais",
    "USA - WI - Oak Creek - St. Mary & St. Antonious",
    "USA - WI - Trinidad - St. Mark",
];