<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title is-uppercase">COVID-19 Disclaimer</p>
    </header>
    <section class="modal-card-body">
      <p class="block">
        While the Archangel Raphael Ministry (A.R.M.) is closely monitoring
        medical and public health advice and taking measures to enhance the
        safety of its all participants, volunteers, and staff—an inherent risk
        of contracting COVID-19 and other contagious diseases exists in any
        public space.
      </p>
      <p class="block">
        By registering as a participant or volunteer, I agree to comply with all
        health and safety protocols implemented by the A.R.M. Committee and St.
        Stephen Christian Retreat and Conference Center in Titusville, Florida.
      </p>
      <p class="block">
        I understand that no one can guarantee that I will not be exposed to and
        contract COVID-19 or other contagious diseases, and therefore attend the
        A.R.M. Convention at my own risk.
      </p>
      <p class="block">
        I agree that this waiver means I give up my right to bring any claims,
        including but not limited to claims of negligence, against the A.R.M.
        Convention, St. Stephen Christian Retreat and Conference Center, and the
        Coptic Orthodox Diocese of the Southern United States.
      </p>
    </section>
    <footer class="modal-card-foot">
      <b-button label="I Agree" type="is-success" @click="handleAgree" />
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import actions from "@/utils/constants/action_types";

export default {
  name: "CovidDisclaimerModal",

  methods: {
    ...mapActions([actions.ACCEPT_COVID_DISCLAIMER]),

    handleAgree() {
      this[actions.ACCEPT_COVID_DISCLAIMER]();
      this.$emit("close");
    },
  },
};
</script>