<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Select Family Members</p>
    </header>
    <section class="modal-card-body">
      <p class="subtitle is-4 is-spaced">
        Who will be attending the convention?
      </p>
      <form-field
        v-for="field in modalFields.fields"
        :key="field.name"
        :field="field"
        v-model="options[field.name]"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button label="Done" type="is-primary" @click="handleDone" />
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import actions from "@/utils/constants/action_types";

import FormField from "@/components/FormField.vue";
import ModalFieldsConfig from "@/config/FamilyMembersModal";

const namespace = "attendee";
const scopedActions = actions[namespace];

export default {
  name: "FamilyMembersModal",

  components: {
    FormField,
  },

  data() {
    return {
      options: {
        father: true,
        mother: true,
        angels: 1,
        otherAdults: 0,
        children: 0,
      },
    };
  },

  computed: {
    modalFields() {
      return ModalFieldsConfig;
    },
  },

  methods: {
    ...mapActions(namespace, [scopedActions.INITIALIZE_FAMILY_MEMBERS]),

    handleDone() {
      this[scopedActions.INITIALIZE_FAMILY_MEMBERS](this.options);
      this.$emit("close");
    },
  },
};
</script>