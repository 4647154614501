import upperFirst from "lodash/upperFirst";

const getMemberTypeLabel = (type) => {
    let label = "";

    if (type === "father" || type === "mother") {
        label = upperFirst(type);
    } else if (type === "angels") {
        label = "Angel";
    } else if (type === "otherAdults" || type === "children") {
        label = type === "otherAdults" ? "Other Adult" : "Child";
    }

    return label;
};

export default getMemberTypeLabel;