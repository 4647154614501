<template>
  <div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
    <div v-if="!loading">
      <header-view :selectedConvention="selectedConvention" />
      <div class="container">
        <p class="title is-4 mt-3 has-text-primary-dark has-text-centered">
          Attendee Registration
        </p>

        <b-message v-if="selectedConvention.registrationClosed" type="is-danger" has-icon>
          Registration is closed for this event.
        </b-message>
        
        <div v-else>
          <fees-card :items="feeItems" />
          <attendee-wizard />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import mixins from "@/components/mixins";

// @ is an alias to /src
import { logError } from "@/utils/logger";
import actions from "@/utils/constants/action_types";
import HeaderView from "@/components/HeaderView.vue";
import FeesCard from "@/components/FeesCard.vue";
import AttendeeWizard from "./AttendeeWizard.vue";

export default {
  name: "AttendeeRegistration",

  components: {
    HeaderView,
    FeesCard,
    AttendeeWizard,
  },

  mixins: [mixins],

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    conventionUrlPath() {
      return this.$route.params.conventionUrlPath;
    },

    feeItems() {
      const feesData = this.selectedConvention.fees;
      return [feesData.room, feesData.adultMeal, feesData.childMeal];
    },

    ...mapState({
      selectedConvention: (state) => state.global.selectedConvention,
    }),
  },

  methods: {
    ...mapActions([actions.FETCH_SELECTED_CONVENTION]),

    ...mapActions("attendee", [actions.attendee.SET_DISCOUNT_CODE]),
  },

  mounted() {
    this[actions.FETCH_SELECTED_CONVENTION]({ urlPath: this.conventionUrlPath })
      .then(() => {
        this.loading = false;

        if (this.$route.query.dc) {
          const discountCode = this.$route.query.dc.trim();
          this[actions.attendee.SET_DISCOUNT_CODE]({ discountCode });
        }
      })
      .catch((err) => {
        const message = "Failed to load convention data";
        logError(message, err);
        this.loading = false;
        this.notifyError(message);
      });
  },
};
</script>

<style scoped>
.container {
  padding: 0 10px;
}
</style>