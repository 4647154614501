<template>
  <section>
    <div
      v-for="item in convention.discountCodes"
      :key="item.code"
      class="card has-background-info-light block item-card"
    >
      <div class="card-content">
        <p class="title is-5 is-uppercase">
          <strong class="mr-2">Max Fee:</strong>
          <span class="has-text-grey-dark">{{ formatAmount(item.maxFee) }}</span>
        </p>
        <p class="subtitle is-6">
          <span class="mr-2">Code:</span
          ><span class="has-text-grey-dark">{{
            item.code
          }}</span>
        </p>
      </div>
      <div class="card-footer">
        <a href="#" @click.prevent="copyUrlToClipboard('Attendee', item.code)" class="card-footer-item is-size-6"
          ><b-icon icon="content-copy" />Copy Attendee URL</a
        >
        <a href="#" @click.prevent="copyUrlToClipboard('Volunteer', item.code)" class="card-footer-item"
          ><b-icon icon="content-copy" />Copy Volunteer URL</a
        >
      </div>
    </div>
  </section>
</template>

<script>
import { formatCurrency } from "@/utils/number";

export default {
  name: "AdminEventDiscountCodes",

  props: {
    convention: Object,
  },

  methods: {
    formatAmount(value) {
      return formatCurrency(value);
    },

    copyUrlToClipboard(type, code) {
      const path = type === "Attendee" ? "register" : "volunteer";
      const url = `${window.location.origin}/${this.convention.urlPath}/${path}?dc=${code}`;
      navigator.clipboard.writeText(url);
      this.$buefy.toast.open(`${type} URL copied`);
    },
  },
};
</script>

<style scoped>
.item-card {
  max-width: 500px;
}
</style>