<template>
  <section>
    <div class="box">
      <p class="title is-4 p-1 mt-4">General</p>

      <p class="is-flex mt-1">
        <strong class="data-label">Start Date:</strong>
        <span>{{ formatDate(convention.startDate) }}</span>
      </p>

      <p class="is-flex mt-1">
        <strong class="data-label">End Date:</strong>
        <span>{{ formatDate(convention.endDate) }}</span>
      </p>

      <p class="is-flex mt-1">
        <strong class="data-label">Location:</strong>
        <span>{{ convention.location }}</span>
      </p>
    </div>

    <div class="box">
      <p class="title is-4 p-1 mt-4">Fees</p>

      <div
        v-for="item in sortedFeesByName"
        :key="item.name"
        class="p-1 block"
      >
        <p class="subtitle mb-2 has-text-grey">{{ formatFeeType(item.name) }}</p>
        <p class="is-flex">
          <strong class="data-label">Amount:</strong>
          <span>{{ formatAmount(item.unitPrice) }}</span>
        </p>
        <p class="is-flex mt-1">
          <strong class="data-label">Description:</strong>
          <span>{{ item.description }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import startCase from "lodash/startCase";
import { formatCurrency } from "@/utils/number";
import { formatFullDate } from "@/utils/dateandtime";

export default {
  name: "AdminEventSettings",

  props: {
    convention: Object,
  },

  computed: {
    sortedFeesByName() {
      const feeNames = Object.keys(this.convention.fees);
      feeNames.sort();
      return feeNames.map((name) => ({ ...this.convention.fees[name], name }));
    },
  },

  methods: {
    formatAmount(value) {
      return formatCurrency(value);
    },

    formatDate(value) {
      return formatFullDate(value);
    },

    formatFeeType(value) {
      return startCase(value);
    },

    copyUrlToClipboard(type, code) {
      const path = type === "Attendee" ? "register" : "volunteer";
      const url = `${window.location.origin}/${this.convention.urlPath}/${path}?dc=${code}`;
      navigator.clipboard.writeText(url);
      this.$buefy.toast.open(`${type} URL copied`);
    },
  },
};
</script>

<style scoped>
.data-label {
  flex-basis: 130px;
  /* text-align: right; */
  margin-right: 10px;
}
</style>