<template>
  <div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
    <div v-if="!loading">
      <header-view :selectedConvention="selectedConvention" />
      <div class="container">
        <p class="title is-4 mt-3 has-text-primary-dark has-text-centered">
          Volunteer Recommendation
        </p>

        <div class="box">
          <section>
            <p class="subtitle is-5 mt-3 has-text-grey">Volunteer Info</p>
            <p class="is-flex mt-2">
              <strong class="data-label">Name:</strong>
              <span>{{ volunteerName }}</span>
            </p>
            <p class="is-flex mt-2">
              <strong class="data-label">Phone:</strong>
              <span>{{ registration.volunteerPhone }}</span>
            </p>
          </section>

          <section
            v-if="registration.recommendationStatus == 'Pending'"
            class="mt-3"
          >
            <b-loading
              :is-full-page="false"
              v-model="updating"
              :can-cancel="false"
            ></b-loading>

            <p class="subtitle is-5 mt-5 has-text-grey">Recommendation</p>

            <b-message
              v-if="completed"
              type="is-success"
              has-icon
              :closable="false"
              >Thank you! Your response was submitted successfully.</b-message
            >

            <div v-else>
              <b-field label="Comments">
                <b-input type="textarea" v-model="comments"></b-input>
              </b-field>

              <div class="buttons">
                <b-button
                  type="is-danger"
                  :disabled="updating"
                  @click="submitRecommendation(false)"
                >
                  I Do Not Approve
                </b-button>

                <b-button
                  type="is-success"
                  :disabled="updating"
                  @click="submitRecommendation(true)"
                >
                  I Approve
                </b-button>
              </div>
            </div>
          </section>

          <section v-else class="mt-3">
            <b-message type="is-info" has-icon :closable="false"
              >A response has already been submitted for this
              recommendation.</b-message
            >
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import mixins from "@/components/mixins";

// @ is an alias to /src
import { logError } from "@/utils/logger";
import actions from "@/utils/constants/action_types";
import HeaderView from "@/components/HeaderView.vue";
import {
  getVolunteerRecommendation,
  updateVolunteerRecommendation,
} from "@/api";

export default {
  name: "VolunteerRecommendation",

  components: {
    HeaderView,
  },

  mixins: [mixins],

  data() {
    return {
      loading: true,
      updating: false,
      completed: false,
      registration: null,
      comments: "",
    };
  },

  computed: {
    ...mapState({
      selectedConvention: (state) => state.global.selectedConvention,
    }),

    conventionUrlPath() {
      return this.$route.params.conventionUrlPath;
    },

    registrationId() {
      return this.$route.params.registrationId;
    },

    token() {
      return this.$route.params.token;
    },

    volunteerName() {
      return `${this.registration.volunteerFirstName} ${this.registration.volunteerLastName}`;
    },
  },

  methods: {
    ...mapActions([actions.FETCH_SELECTED_CONVENTION]),

    submitRecommendation(approved) {
      this.updating = true;
      const status = approved ? "Approved" : "Denied";
      const payload = { status, comments: this.comments };

      updateVolunteerRecommendation(
        this.selectedConvention.id,
        this.registrationId,
        this.token,
        payload
      )
        .then(() => {
          this.updating = false;
          this.completed = true;
        })
        .catch((err) => {
          const message = `Failed to submit recommendation. ${err?.message}`;
          logError(message, err);
          this.updating = false;
          this.notifyError(message);
        });
    },
  },

  mounted() {
    this[actions.FETCH_SELECTED_CONVENTION]({
      urlPath: this.conventionUrlPath,
    })
      .then(() => {
        return getVolunteerRecommendation(
          this.selectedConvention.id,
          this.registrationId,
          this.token
        ).then((registrationObj) => {
          this.registration = registrationObj;
          this.loading = false;
        });
      })
      .catch((err) => {
        const message = `Failed to load data. ${err?.message}`;
        logError(message, err);
        this.loading = false;
        this.notifyError(message);
      });
  },
};
</script>

<style scoped>
.container {
  padding: 0 10px;
}
.data-label {
  flex-basis: 100px;
  /* text-align: right; */
  margin-right: 10px;
}
</style>