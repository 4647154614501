import Vue from 'vue';
import VueRouter from 'vue-router';

import AttendeeRegistration from "@/components/attendee/AttendeeRegistration.vue";
import VolunteerRegistration from "@/components/volunteer/VolunteerRegistration.vue";
import ConfirmationPage from "@/components/ConfirmationPage.vue";
import AdminBaseView from "@/components/admin/AdminBaseView.vue";
import VolunteerRecommendation from "@/components/volunteer/VolunteerRecommendation.vue";

import adminRoutes from "./admin-routes";
import store from "@/store";
import { log } from "@/utils/logger";
import { getCurrentUser } from "@/service/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/arm-2024/register'
  },
  {
    path: '/admin',
    component: AdminBaseView,
    children: adminRoutes,
  },
  {
    path: '/:conventionUrlPath/volunteer/:registrationId/recommendation/:token',
    name: 'volunteer-recommendation',
    component: VolunteerRecommendation
  },
  {
    path: '/:conventionUrlPath/register',
    name: 'register',
    component: AttendeeRegistration
  },
  {
    path: '/:conventionUrlPath/volunteer',
    name: 'volunteer',
    component: VolunteerRegistration
  },
  {
    path: '/:conventionUrlPath/confirmation',
    name: 'confirmation',
    component: ConfirmationPage
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.initAuth)) {

    // check auth status
    if (!store.state.admin.auth.user) {
      log('Checking auth status..');
      await getCurrentUser();
    }
    next();

  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    // check auth status
    if (!store.state.admin.auth.user) {
      log('User not authenticated..');
      next({
        name: 'admin-login',
        query: { redirect: to.fullPath }
      });
    }
    else {
      next();
    }

  } else {
    next();
  }
});

export default router
