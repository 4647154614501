import { log } from "@/utils/logger";

const loadPayPalScript = (clientId) => {
    return new Promise((resolve) => {
        log('Loading PayPal script..');
        const scriptTag = document.createElement('script');
        scriptTag.src = `https://www.paypal.com/sdk/js?client-id=${clientId || 'sb'}&disable-funding=paylater`;
        scriptTag.async = true;
        scriptTag.onload = () => {
            log('PayPal script loaded successfully.');
            resolve();
        };

        document.body.appendChild(scriptTag);
    });
};

export default loadPayPalScript;