import axios from 'axios';

const region = 'us-east1';
const projectId = 'arm-convention-registration';

const baseURL =
    process.env.NODE_ENV === 'production'
        ? `https://${region}-${projectId}.cloudfunctions.net/api`
        : `http://localhost:5001/${projectId}/${region}/api`;

const instance = axios.create({
    baseURL
});

export async function getConventionByUrlPath(urlPath) {
    const url = `/conventions/path/${urlPath}`;
    const response = await instance.get(url);
    return response.data;
}

export async function getConventionById(conventionId) {
    const url = `/conventions/${conventionId}`;
    const response = await instance.get(url);
    return response.data;
}

export async function getVolunteerRecommendation(conventionId, registrationId, token) {
    const url = `/conventions/${conventionId}/volunteers/${registrationId}/recommendation/${token}`;
    const response = await instance.get(url);
    return response.data;
}

export async function updateVolunteerRecommendation(conventionId, registrationId, token, payload) {
    const url = `/conventions/${conventionId}/volunteers/${registrationId}/recommendation/${token}`;
    const response = await instance.post(url, payload);
    return response.data;
}

export async function generateReviewAndTotal(conventionId, registration) {
    const url = `/conventions/${conventionId}/registrations/review-total`;
    const response = await instance.post(url, registration);
    return response.data;
}

export async function processTransaction(conventionId, registration, orderId) {
    const url = `/conventions/${conventionId}/registrations/orders/${orderId}`;
    const response = await instance.post(url, registration);
    return response.data;
}

export async function saveRegistrationNoPayment(conventionId, registration) {
    const url = `/conventions/${conventionId}/registrations`;
    const response = await instance.post(url, registration);
    return response.data;
}

export async function getClientConfiguration() {
    const url = `/client-configuration`;
    const response = await instance.get(url);
    return response.data;
}

export async function getPriestNames() {
    const url = `/priests`;
    const response = await instance.get(url);
    return response.data;
}