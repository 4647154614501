<template>
  <div class="container box">
    <b-message title="Unauthorized" type="is-danger" has-icon :closable="false">
      You are not authorized to view this page.
    </b-message>
  </div>
</template>

<script>
export default {};
</script>