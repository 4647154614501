import Vue from 'vue';
import Vuex from 'vuex';

import global from './modules/global';
import admin from './modules/admin';
import attendee from './modules/attendee';
import volunteer from './modules/volunteer';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    global,
    admin,
    attendee,
    volunteer
  },
  strict: debug,
})
