const debug = process.env.NODE_ENV === 'development';

export function log(...messages) {
    if (debug) {
        console.log(...messages);
    }
}

export function logError(...messages) {
    if (debug) {
        console.error(...messages);
    }
}