<template>
  <div class="container box">
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
    <div v-if="!loading">
      <p v-if="upcomingEvents.length" class="title is-4 has-text-grey">
        Upcoming Events
      </p>
      <admin-event-list-item
        v-for="item in upcomingEvents"
        :key="item.id"
        :convention="item"
      />

      <hr v-if="pastEvents.length" />
      <p v-if="pastEvents.length" class="title is-4 has-text-grey">
        Past Events
      </p>
      <admin-event-list-item
        v-for="item in pastEvents"
        :key="item.id"
        :convention="item"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import mixins from "@/components/mixins";

// @ is an alias to /src
import { log, logError } from "@/utils/logger";
import actions from "@/utils/constants/action_types";
import AdminEventListItem from "./AdminEventListItem.vue";

export default {
  name: "AdminEvents",

  mixins: [mixins],

  components: {
    AdminEventListItem,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapState({
      conventions: (state) => state.admin.conventions,
    }),

    upcomingEvents() {
      return this.conventions.filter(
        (conv) => new Date(conv.endDate) >= new Date()
      );
    },

    pastEvents() {
      return this.conventions.filter(
        (conv) => new Date(conv.endDate) < new Date()
      );
    },
  },

  methods: {
    ...mapActions("admin", [actions.admin.FETCH_ALL_CONVENTIONS]),
  },

  mounted() {
    if (!this.conventions.length) {
      log('AdminEvents fetching conventions..');
      this[actions.admin.FETCH_ALL_CONVENTIONS]()
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          const message = `Failed to load conventions. ${err?.message}`;
          logError(message, err);
          this.loading = false;
          this.notifyError(message);
        });
    } else {
      this.loading = false;
    }
  },
};
</script>