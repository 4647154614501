<template>
  <section class="fees-container">
    <b-collapse class="card" animation="slide" aria-id="fees">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="fees"
          :aria-expanded="props.open"
        >
          <p class="card-header-title has-text-primary">Fees</p>
          <a class="card-header-icon">
            <span>{{ props.open ? "Hide" : "Show" }}</span>
            <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"></b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <div v-for="(item, idx) in items" :key="idx" class="block">
          <hr v-if="idx > 0" />
          <div class="is-flex is-justify-content-space-between">
            <span>{{ item.description }}</span>
            <span>&nbsp;&nbsp;</span>
            <span>${{ item.unitPrice }}</span>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <slot></slot>
      </div>
    </b-collapse>
  </section>
</template>

<script>
export default {
  name: "FeesCard",

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.fees-container {
  max-width: 800px;
  margin: 15px auto;
}
</style>