<template>
  <div>
    <b-navbar type="is-info">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ name: 'admin-home' }">
          <p
            class="
              title
              is-uppercase
              has-background-info-light has-text-info
              logo-text
            "
          >
            ARM Admin
          </p>
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" :to="{ name: 'admin-home' }">
          <b-icon icon="calendar-multiple"></b-icon>
          <span class="ml-1">Events</span>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'admin-test-payment' }">
          <b-icon icon="credit-card-outline"></b-icon>
          <span class="ml-1">Test Payment</span>
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a @click="handleLoginLogout" class="button is-dark">
              {{ authUser ? "Sign out" : "Sign in" }}
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="my-3 mx-2">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AdminBaseView",

  computed: {
    ...mapState({
      authUser: (state) => state.admin.auth.user,
    }),
  },

  methods: {
    handleLoginLogout() {
      const routeName = this.authUser ? "admin-logout" : "admin-login";
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped>
.logo-text {
  padding: 5px 10px;
  border-radius: 5px;
}
</style>