<template>
  <b-autocomplete
    v-model="search"
    group-field="group"
    group-options="items"
    open-on-focus
    clearable
    placeholder="Type or select from list"
    field="label"
    :data="filteredOptionList"
    @select="onSelection"
  >
  </b-autocomplete>
</template>

<script>
export default {
  name: "SelectFormField",

  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      search: this.value || "",
    };
  },

  computed: {
    filteredOptionList() {
      const filteredOptions = [];
      this.field.optionList.forEach((element) => {
        const items = element.items.filter((item) => {
          if (typeof item === "string") {
            return item.toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
          } else if (typeof item === "object") {
            return (
              item.label.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
            );
          }
        });
        if (items.length) {
          filteredOptions.push({ group: element.group, items });
        }
      });
      return filteredOptions;
    },
  },

  methods: {
    onSelection(option) {
      if (typeof option === "string") {
        this.$emit("input", option);
      } else if (typeof option === "object") {
        this.$emit("input", option?.value || "");
      }
    },
  },
};
</script>