<template>
  <div class="container box content-container">
    <b-message v-if="logoutSuccess" type="is-success" title="Success" has-icon :closable="false">
      You have been logged out.
    </b-message>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { log } from "@/utils/logger";
import actions from "@/utils/constants/action_types";

export default {
  name: "AdminLogout",

  data() {
    return {
      logoutSuccess: false,
    };
  },

  mounted() {
    this.processLogout()
      .then(() => {
        this.logoutSuccess = true;
      })
      .catch((err) =>
          log("Warning: Logout failed. Error:", err.message)
        );
  },

  methods: {
      ...mapActions("admin", { processLogout: actions.admin.HANDLE_LOGOUT }),
  },
};
</script>

<style scoped>
.content-container {
  padding: 20px;
}
</style>