export default {
    mens: [
        "Men's Small",
        "Men's Medium",
        "Men's Large",
        "Men's X-Large",
        "Men's 2X-Large",
        "Men's 3X-Large",
    ],
    womens: [
        "Women's Small",
        "Women's Medium",
        "Women's Large",
        "Women's X-Large",
        "Women's 2X-Large",
        "Women's 3X-Large",
    ],
    youth: [
        "Youth Small",
        "Youth Medium",
        "Youth Large",
        "Youth X-Large"
    ]
};