<template>
  <div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <section class="box" v-if="reviewData">
      <p
        class="
          title
          is-5
          has-background-primary has-text-white
          p-2
          is-uppercase
        "
      >
        Review and Pay
      </p>

      <div v-for="(item, idx) in reviewData.lineItems" :key="idx" class="block">
        <div class="is-flex is-justify-content-space-between">
          <span :class="{ 'has-text-danger': item.lineTotal < 0 }">{{
            item.description
          }}</span>
          <span v-if="item.lineTotal < 0" class="has-text-danger"
            >-${{ item.lineTotal * -1 }}</span
          >
          <span v-else>${{ item.lineTotal }}</span>
        </div>
        <hr />
      </div>
      <div class="is-flex is-justify-content-space-between is-size-4">
        <strong>Total</strong>
        <strong>${{ reviewData.total }}</strong>
      </div>
    </section>

    <section
      v-if="reviewData && reviewData.total == 0"
      class="is-flex is-justify-content-center"
    >
      <b-button
        size="is-medium"
        type="is-primary"
        @click.prevent="completeRegistrationNoPaymentRequired"
      >
        Complete Registration
      </b-button>
    </section>

    <section class="payment-button" v-show="showPaypalButton">
      <paypal-buttons :on-approve="onApprove" :create-order="createOrder" />
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

import mixins from "@/components/mixins";

import { log, logError } from "@/utils/logger";
import { generateReviewAndTotal, saveRegistrationNoPayment, processTransaction } from "@/api";

export default {
  name: "ReviewAndPay",

  components: {},

  mixins: [mixins],

  inject: ["namespace"],

  props: {
    isActiveStep: Boolean,
  },

  data() {
    return {
      isLoading: false,
      reviewData: null,
    };
  },

  watch: {
    isActiveStep: function (val) {
      if (val) {
        this.generateReviewData();
      }
    },
  },

  computed: {
    showPaypalButton() {
      return (
        this.isActiveStep &&
        this.reviewData != null &&
        this.reviewData.total > 0
      );
    },

    ...mapState({
      selectedConvention: (state) => state.global.selectedConvention,
    }),
  },

  mounted() {
    log("ReviewAndPay mounted");
  },

  methods: {
    generateReviewData() {
      log("Getting review data..");
      this.isLoading = true;
      generateReviewAndTotal(
        this.selectedConvention.id,
        this.$store.state[this.namespace]
      )
        .then((data) => {
          this.reviewData = data;
          this.isLoading = false;
        })
        .catch((err) => {
          const message = "Failed to load data";
          logError(message, err);
          this.isLoading = false;
          this.notifyError(message);
        });
    },

    completeRegistrationNoPaymentRequired() {
      this.isLoading = true;
      saveRegistrationNoPayment(
        this.selectedConvention.id,
        this.$store.state[this.namespace]
      )
        .then(() => {
          this.isLoading = false;
          this.onPaymentCompleted();
        })
        .catch((err) => {
          const message = "Failed to save registration";
          logError(message, err);
          this.isLoading = false;
          this.notifyError(message);
        });
    },

    createOrder(data, actions) {
      log("createOrder was called");
      return actions.order.create({
        purchase_units: [
          {
            description: "ARM Convention Registration",
            amount: {
              value: this.reviewData.total,
            },
          },
        ],
      });
    },

    onApprove(data) {
      log("onApprove was called");
      this.isLoading = true;
      return processTransaction(
        this.selectedConvention.id,
        this.$store.state[this.namespace],
        data.orderID
      )
        .then(() => {
          this.isLoading = false;
          this.onPaymentCompleted();
        })
        .catch((err) => {
          const message = "Failed to complete registration";
          logError(message, err);
          this.isLoading = false;
          this.notifyError(message);
        });
    },

    onPaymentCompleted() {
      this.$router.push({
        name: "confirmation",
        params: { conventionUrlPath: this.selectedConvention.urlPath },
      });
    },
  },
};
</script>

<style scoped>
.payment-button {
  max-width: 300px;
  margin: auto;
}
</style>