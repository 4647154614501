<template>
  <div class="container box">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <p class="title is-4 has-text-grey">Test Payment</p>

    <b-message type="is-info" >The transaction will appear in PayPal as <strong>{{
      transactionDescription
    }}</strong></b-message>

    <section class="amount-input mb-5">
      <b-field label="Dollar Amount">
        <b-numberinput
          v-model="amount"
          :min="1"
        ></b-numberinput>
      </b-field>
    </section>

    <section class="payment-button">
      <paypal-buttons :on-approve="onApprove" :create-order="createOrder" />
    </section>
  </div>
</template>

<script>
import mixins from "@/components/mixins";

import { log, logError } from "@/utils/logger";
import { processTestPayment } from "@/admin-api";

export default {
  name: "AdminTestPayment",

  components: {},

  mixins: [mixins],

  data() {
    return {
      isLoading: false,
      amount: 1,
    };
  },

  computed: {
    transactionDescription() {
      return "ARM Convention - Test Payment";
    },
  },

  methods: {
    createOrder(data, actions) {
      log("createOrder was called");
      return actions.order.create({
        purchase_units: [
          {
            description: this.transactionDescription,
            amount: {
              value: this.amount,
            },
          },
        ],
      });
    },

    onApprove(data) {
      log("onApprove was called");
      this.isLoading = true;
      return processTestPayment(data.orderID)
        .then(() => {
          this.isLoading = false;
          this.$buefy.notification.open({
            duration: 5000,
            message: "Payment processed successfully.",
            position: "is-bottom",
            type: "is-success",
            hasIcon: true,
            indefinite: false,
          });
        })
        .catch((err) => {
          const message = `Failed to process payment. ${err?.message}`;
          logError(message, err);
          this.isLoading = false;
          this.notifyError(message);
        });
    },
  },
};
</script>

<style scoped>
.payment-button {
  max-width: 300px;
  margin: auto;
}
.amount-input {
  max-width: 300px;
  margin: auto;
}
</style>