<template>
  <div class="container box content-container">
    <p class="title is-4 has-text-grey">Please sign in to continue</p>

    <b-message v-if="errorMsg" type="is-danger">
      {{ errorMsg }}
    </b-message>

    <b-message v-if="successMsg" type="is-info">
      {{ successMsg }}
    </b-message>

    <form-field key="emailInput" :field="emailFieldConfig" :value="email" :fieldError="emailError" @input="updateEmail"
      @keyup-enter="handleLogin" />

    <form-field key="passwordInput" v-if="!forgotPassword" :field="passwordFieldConfig" :fieldError="null" v-model="password"
      @keyup-enter="handleLogin" />

    <button v-if="!forgotPassword" class="button is-fullwidth is-info" @click="handleLogin">
      Sign in
    </button>
    <button v-else class="button is-fullwidth is-info" @click="handleResetPassword">
      Reset Password
    </button>

    <button v-if="!forgotPassword" class="button is-fullwidth is-ghost" @click="showResetPasswordForm">
      Forgot Password?
    </button>
    <button v-else class="button is-fullwidth is-ghost" @click="forgotPassword = false">
      Back to sign in
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { validateField } from "@/utils/validators";
import FormField from "@/components/FormField.vue";
import actions from "@/utils/constants/action_types";
import { log, logError } from "@/utils/logger";

export default {
  name: "AdminLogin",

  components: {
    FormField,
  },

  data() {
    return {
      email: null,
      password: null,
      emailError: null,
      errorMsg: false,
      successMsg: false,
      forgotPassword: false,
    };
  },

  computed: {
    ...mapState({
      authUser: (state) => state.admin.auth.user,
    }),

    emailFieldConfig() {
      return {
        name: "email",
        type: "email",
        placeholder: "Enter email address",
      };
    },

    passwordFieldConfig() {
      return {
        name: "password",
        type: "password",
        placeholder: "Enter password",
      };
    },
  },

  mounted() {
    if (this.authUser != null) {
      this.onSuccessfulLogin();
    }
  },

  methods: {
    ...mapActions("admin", { processLogin: actions.admin.HANDLE_LOGIN, processPasswordReset: actions.admin.HANDLE_RESET_PASSWORD }),

    updateEmail(val) {
      this.email = val;
      this.validateForm();
    },

    validateForm() {
      this.emailError = validateField(this.emailFieldConfig, this.email);
      return !this.emailError;
    },

    handleLogin() {
      this.errorMsg = false;
      if (this.validateForm()) {
        const payload = { email: this.email, password: this.password };
        this.processLogin(payload)
          .then(this.onSuccessfulLogin)
          .catch((err) => {
            this.errorMsg = 'Login failed. Please check your credentials and try again.';
            logError(err);
          });
      }
    },

    showResetPasswordForm() {
      this.forgotPassword = true;
      this.errorMsg = false;
      this.password = '';
    },

    handleResetPassword() {
      this.errorMsg = false;
      if (this.validateForm()) {
        const payload = { email: this.email };
        this.processPasswordReset(payload)
          .then(() => {
            this.successMsg = 'Password reset link will be emailed to you shortly.';
            this.forgotPassword = false;
          })
          .catch((err) => {
            this.errorMsg = 'Password reset failed. Please make sure your email is correct.';
            logError(err);
          });
      }
    },

    onSuccessfulLogin() {
      const redirect = this.$route.query.redirect;
      const nextRoute = redirect ? { path: redirect } : { name: "admin-home" };
      this.$router
        .replace(nextRoute)
        .catch((err) =>
          log("Warning: Router navigation failed. Error:", err.message)
        );
    },
  },
};
</script>

<style scoped>
.content-container {
  padding: 20px;
  max-width: 800px;
}

.is-ghost {
  color: #167df0;
}
</style>