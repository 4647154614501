import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";

import { log, logError } from "@/utils/logger";

const firebaseConfig = {
    apiKey: "AIzaSyBaO4E9j8V74IFV_wlRysOYaB36EJitzCc",
    authDomain: "arm-convention-registration.firebaseapp.com",
    databaseURL: "https://arm-convention-registration.firebaseio.com",
    projectId: "arm-convention-registration",
    storageBucket: "arm-convention-registration.appspot.com",
    messagingSenderId: "417944954289",
    appId: "1:417944954289:web:92644dc8799675a1850990"
};

initializeApp(firebaseConfig);

export function getCurrentUser() {
    log('Getting current user..');
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, user => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
}

export function getIdToken() {
    return getAuth().currentUser.getIdToken(false)
        .catch(err => {
            logError('Failed to get idToken. Error', err);
            return null;
        });
}

export function registerAuthListener(listener) {
    log('Registering auth listener..');
    const auth = getAuth();
    onAuthStateChanged(auth, listener);
}

export function login(email, password) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
    const auth = getAuth();
    return signOut(auth);
}

export function resetPassword(email) {
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email);
}
