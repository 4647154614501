export { default as USStates } from "./us_states";
export { default as CanadianProvinces } from "./ca_provinces";
export { default as TShirtSizes } from "./tshirt_sizes";
export { default as USChurches } from "./us_churches";
export { default as CanadianChurches } from "./ca_churches";
export { default as OtherChurches } from "./other_churches";

export const RoomPreferences = [
    'Wheelchair accessible',
    'Downstairs',
    'Upstairs',
    'Garden view',
    'Pool view',
    'Water View',
    '1 King Bed',
    '2 Queen Beds'
];

export const GradeLevels = [
    'Graduate / Adult',
    'College',
    '12th Grade',
    '11th Grade',
    '10th Grade',
    '9th Grade',
    '8th Grade',
    '7th Grade',
    '6th Grade',
    '5th Grade',
    '4th Grade',
    '3rd Grade',
    '2nd Grade',
    '1st Grade',
    'Kindergarten',
    'Pre-K',
    '4 years old & under'
];

export const MedicalConditions = [
    'Allergies',
    'ADD/ADHD',
    'Asthma',
    'Hearing Loss',
    'Poor or Limited Vision',
    'Epilepsy',
    'Heart Condition',
    'Diabetes',
    'Learning Disabilities',
    'Separation Anxiety',
    'Fears',
    'OCD',
    'Behavioral Issues',
    'Autism',
    'Other'
];