<template>
  <div>
    <div class="mb-4 is-flex is-justify-content-start">
      <b-button
        type="is-primary"
        icon-left="plus-circle"
        inverted
        @click="launchAddModal"
        >Add Family Member</b-button
      >
    </div>

    <div
      class="box"
      v-for="familyMember in familyMembers"
      :key="familyMember.key"
    >
      <p
        class="
          title
          is-5
          has-background-primary has-text-white
          p-2
          is-uppercase
          is-flex
          is-justify-content-space-between
          is-align-items-center
        "
      >
        {{ getFamilyMemberTitle(familyMember) }}
        <a href="#" @click.prevent="confirmDelete(familyMember)"
          ><b-icon icon="close-circle" size="is-medium" type="is-white"></b-icon
        ></a>
      </p>
      <form-field
        v-for="field in getFields(familyMember)"
        :key="field.name"
        :field="field"
        :value="getFieldValue(field.name, familyMember)"
        :fieldError="getFieldError(field.name, familyMember)"
        @input="(value) => handleFieldChange(value, field.name, familyMember)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import get from "lodash/get";

import mixins from "@/components/mixins";

import actions from "@/utils/constants/action_types";
import getMemberTypeLabel from "@/utils/getMemberTypeLabel";
import { validateField } from "@/utils/validators";
import FamilyMembersConfig from "@/config/FamilyMembers";
import FormField from "@/components/FormField.vue";
import FamilyMembersModal from "./FamilyMembersModal.vue";
import AddFamilyMemberModal from "./AddFamilyMemberModal.vue";

const namespace = "attendee";
const scopedActions = actions[namespace];

export default {
  name: "FamilyMembersStep",

  components: {
    FormField,
  },

  mixins: [mixins],

  props: {
    isActiveStep: Boolean,
  },

  data() {
    return {
      errors: [],
    };
  },

  watch: {
    isActiveStep: function (val) {
      if (val) {
        this.displayModalIfNecessary();
      }
    },
  },

  computed: {
    ...mapState(namespace, ["familyMembers"]),
  },

  methods: {
    ...mapActions(namespace, [scopedActions.REMOVE_FAMILY_MEMBER]),

    displayModalIfNecessary() {
      if (!this.familyMembers?.length) {
        this.$buefy.modal.open({
          parent: this,
          component: FamilyMembersModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      }
    },

    getFamilyMemberTitle(familyMember) {
      const { type, key } = familyMember;
      const memberTypeLabel = getMemberTypeLabel(type);
      const firstName = familyMember.firstName || "";

      if (type === "father" || type === "mother") {
        return firstName
          ? `${memberTypeLabel} - ${firstName}`
          : memberTypeLabel;
      }

      const membersOfSameType = this.familyMembers.filter(
        (item) => item.type === type
      );
      const index = membersOfSameType.findIndex((item) => item.key === key);

      return firstName
        ? `${memberTypeLabel} - ${firstName}`
        : `${memberTypeLabel} ${index + 1}`;
    },

    getFields(familyMember) {
      if (["father", "mother", "otherAdults"].includes(familyMember.type)) {
        return FamilyMembersConfig.commonFields;
      } else if (familyMember.type === "children") {
        return FamilyMembersConfig.childFields;
      } else if (familyMember.type === "angels") {
        return FamilyMembersConfig.angelFields;
      }
      return [];
    },

    launchAddModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddFamilyMemberModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
      });
    },

    confirmDelete(familyMember) {
      if (this.isLastAngel(familyMember)) {
        this.notifyError("At least one angel is required on the registration.", false);
        return;
      }

      const familyMemberTitle = this.getFamilyMemberTitle(familyMember);
      this.$buefy.dialog.confirm({
        title: `Removing ${familyMemberTitle}`,
        message: `Are you sure you want to <b>remove ${familyMemberTitle}</b>?`,
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this[scopedActions.REMOVE_FAMILY_MEMBER]({ key: familyMember.key }),
      });
    },

    isLastAngel(familyMember) {
      const { type } = familyMember;

      if (type !== "angels") {
        return false;
      }

      const angels = this.familyMembers.filter((item) => item.type === type);

      return angels.length === 1;
    },

    validateStep() {
      const allStepFields = this.familyMembers.flatMap((familyMember) => {
        const fields = this.getFields(familyMember);
        return fields.map((field) => {
          return {
            prop: field.name,
            value: this.getFieldValue(field.name, familyMember),
            familyMember,
          };
        });
      });

      allStepFields.forEach((f) => this.handleFieldValiation(f));
      return this.errors.length === 0;
    },

    handleFieldValiation({ prop, value, familyMember }) {
      const errorKey = this.getFieldKey(prop, familyMember);
      this.clearError(errorKey);
      const fieldConfig = this.getFieldConfig(prop);
      const error = validateField(fieldConfig, value);
      if (error) {
        this.errors.push({ key: errorKey, message: error });
      }
    },

    handleFieldChange(value, prop, familyMember) {
      if (typeof value == "string") {
        value = value.trim();
      }

      const payload = {
        prop,
        value,
        familyMember,
      };
      const actionType = `${namespace}/${actions[namespace].UPDATE_FAMILY_MEMBER_FIELD}`;
      this.$store.dispatch(actionType, payload);
      this.handleFieldValiation(payload);
    },

    getFieldConfig(prop) {
      const field = FamilyMembersConfig.angelFields.find(
        (f) => f.name === prop
      );
      return field;
    },

    getFieldKey(prop, familyMember) {
      return `${familyMember.key}.${prop}`;
    },

    getFieldValue(prop, familyMember) {
      return get(familyMember, prop);
    },

    getFieldError(prop, familyMember) {
      const fieldKey = this.getFieldKey(prop, familyMember);
      const error = this.errors.find((e) => e.key === fieldKey);
      return error?.message;
    },

    clearError(key) {
      const idx = this.errors.findIndex((e) => e.key === key);
      if (idx > -1) {
        this.errors.splice(idx, 1);
      }
    },
  },
};
</script>