import { TShirtSizes, GradeLevels, MedicalConditions } from "@/utils/constants";

const required = true;

const commonFields = [
    {
        name: "firstName",
        type: "text",
        required,
    },
    {
        name: "lastName",
        type: "text",
        required,
    },
    {
        name: "tshirtSize",
        label: "T-Shirt Size",
        type: "select",
        required,
        optionList: [
            {
                group: "Men",
                items: TShirtSizes.mens
            },
            {
                group: "Women",
                items: TShirtSizes.womens
            },
            {
                group: "Youth",
                items: TShirtSizes.youth
            },
        ]
    },
    {
        name: "addMeals",
        type: "switch",
    },
];

const childFields = [
    ...commonFields,
    {
        name: "birthday",
        type: "date",
        disableFutureDates: true,
        required,
    },
    {
        name: "gradeLevel",
        type: "select",
        optionList: [
            {
                group: "Select one",
                items: GradeLevels
            },
        ]
    },
];

const angelFields = [
    ...childFields,
    {
        name: "physicianFirstName",
        label: "Physician's First Name",
        type: "text",
        required,
    },
    {
        name: "physicianLastName",
        label: "Physician's Last Name",
        type: "text",
        required,
    },
    {
        name: "physicianPhone",
        label: "Physician's Phone Number",
        type: "phone",
        required,
        placeholder: "555-555-5555",
    },
    {
        name: "medicalConditions",
        type: "multi-select",
        optionList: MedicalConditions,
    },
    {
        name: "medicalConditionsComments",
        label: "Medical Conditions Details",
        infoText: "Please describe any other medical conditions or behavioral issues that we should be aware of.",
        type: "textarea"
    },
    {
        name: "dietaryIssuesComments",
        label: "Dietary Issues Details",
        infoText: "Please describe the dietary issues and how you would prefer we handle it.",
        type: "textarea"
    },
];

export default { commonFields, childFields, angelFields };