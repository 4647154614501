import Vue from 'vue';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import { LoadingProgrammatic as Loading } from 'buefy';

import loadPayPalScript from './loadPaypalScript';
import App from './App.vue';
import router from './router';
import store from './store';
import { getClientConfiguration } from './api';
import { registerAuthListener } from "@/service/firebase";
import actions from "@/utils/constants/action_types";

Vue.config.productionTip = false;

Vue.use(Buefy);

const loadingComponent = Loading.open({
  container: null
});

getClientConfiguration().then(({ paypalClientId }) => {
  loadPayPalScript(paypalClientId).then(() => {
    loadingComponent.close();
    const PayPalButton = window.paypal.Buttons.driver("vue", Vue);
    Vue.component("paypal-buttons", PayPalButton);

    registerAuthListener((user) => {
      store.dispatch(`admin/${actions.admin.HANDLE_AUTH_CHANGE}`, { user });
    });

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  });
});

