export default [
    "Canada - AB - Calgary - Archangel Michael",
    "Canada - AB - Calgary - St. Mina",
    "Canada - AB - Edmonton - St. Mary & St. Mark",
    "Canada - BC - Surrey - St. George",
    "Canada - MB - Winnipeg - St. Mark",
    "Canada - NB - St. John - Holy Virgin Mary & St. George",
    "Canada - NS - Halifax - St. Mena & St. George",
    "Canada - ON - Ajax - St. Mary & St. Abraam",
    "Canada - ON - Barrie - St. John & St. Elizabeth",
    "Canada - ON - Brampton - Archangel Michael & St. Tekla",
    "Canada - ON - Elgin Mills - St. Mary & St. Joseph",
    "Canada - ON - Etobicoke - Ti Agia Maria & St. Demiana",
    "Canada - ON - Hamilton - St. Mina",
    "Canada - ON - Kingston - St. Mena",
    "Canada - ON - Kitchener - St. Mary",
    "Canada - ON - London - St. Paul the Anchorite",
    "Canada - ON - Markham - St. Maurice & St. Verena",
    "Canada - ON - Mississauga - St. Anthony & St. Philopateer",
    "Canada - ON - Mississauga - Virgin Mary & St. Athanasius",
    "Canada - ON - Nepean - St. Mary",
    "Canada - ON - North York - St. George & St. Rueiss",
    "Canada - ON - Ottawa - St. George & St. Anthony",
    "Canada - ON - Richmond Hill - St. Mary & St. Joseph",
    "Canada - ON - St. Catharines - St. George & St. Mercurius",
    "Canada - ON - Stouffville - St. Bishoy",
    "Canada - ON - Toronto - St. Mark",
    "Canada - ON - Windsor - St. Mary & St. Moses",
    "Canada - QC - Laval - Archangel Michael & Abu Seifein",
    "Canada - QC - Montreal - St. Mark",
    "Canada - QC - Montreal - St. Peter & St. Paul",
    "Canada - QC - Pierrefonds - St. George & St. Joesph",
    "Canada - QC - Quebec City",
    "Canada - QC - St. Hubert - Virgin Mary",
];