import AdminLogin from "@/components/admin/AdminLogin.vue";
import AdminLogout from "@/components/admin/AdminLogout.vue";
import AdminNotAuthorized from "@/components/admin/AdminNotAuthorized.vue";
import AdminEvents from "@/components/admin/AdminEvents.vue";
import AdminEventDetails from "@/components/admin/AdminEventDetails.vue";
import AdminTestPayment from "@/components/admin/AdminTestPayment.vue";

const routes = [
    {
        path: '',
        redirect: 'events',
    },
    {
        path: 'login',
        name: 'admin-login',
        component: AdminLogin,
        meta: { initAuth: true },
    },
    {
        path: 'logout',
        name: 'admin-logout',
        component: AdminLogout
    },
    {
        path: 'unauthorized',
        name: 'admin-unauthorized',
        component: AdminNotAuthorized
    },
    {
        path: 'events',
        name: 'admin-home',
        component: AdminEvents,
        meta: { requiresAuth: true },
    },
    {
        path: 'events/:eventId',
        name: 'admin-event-details',
        component: AdminEventDetails,
        meta: { requiresAuth: true },
    },
    {
        path: 'test-payment',
        name: 'admin-test-payment',
        component: AdminTestPayment,
        meta: { requiresAuth: true },
    },

];

export default routes;