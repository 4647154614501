<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>
    <div v-if="!loading">
      <header-view :selectedConvention="selectedConvention" />
      <div class="container">
        <p class="title is-4 mt-3 has-text-primary-dark has-text-centered">
          Volunteer Registration
        </p>

        <b-message v-if="selectedConvention.registrationClosed" type="is-danger" has-icon>
          Registration is closed for this event.
        </b-message>

        <div v-else>
          <fees-card :items="feeItems">
            <b-message type="is-danger" has-icon class="m-2">
              These prices are reduced for the volunteers only. Anyone bringing guests or family members, please call Abouna at 239-273-5029 directly <strong>PRIOR</strong> to registering.
            </b-message>
          </fees-card>
          <volunteer-wizard />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import mixins from "@/components/mixins";

// @ is an alias to /src
import { logError } from "@/utils/logger";
import actions from "@/utils/constants/action_types";
import HeaderView from "@/components/HeaderView.vue";
import FeesCard from "@/components/FeesCard.vue";
import VolunteerWizard from "./VolunteerWizard.vue";

export default {
  name: "VolunteerRegistration",

  components: {
    HeaderView,
    FeesCard,
    VolunteerWizard,
  },

  mixins: [mixins],

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    conventionUrlPath() {
      return this.$route.params.conventionUrlPath;
    },

    feeItems() {
      const feesData = this.selectedConvention.fees;
      return [feesData.volunteerFullStay, feesData.volunteerPartialStay];
    },

    ...mapState({
      selectedConvention: (state) => state.global.selectedConvention,
    }),
  },

  methods: {
    ...mapActions([
      actions.FETCH_SELECTED_CONVENTION,
      actions.FETCH_PRIEST_NAMES,
    ]),

    ...mapActions("volunteer", [actions.volunteer.SET_DISCOUNT_CODE]),
  },

  mounted() {
    Promise.all([
      this[actions.FETCH_SELECTED_CONVENTION]({
        urlPath: this.conventionUrlPath,
      }),
      this[actions.FETCH_PRIEST_NAMES](),
    ])
      .then(() => {
        this.loading = false;

        if (this.$route.query.dc) {
          const discountCode = this.$route.query.dc.trim();
          this[actions.volunteer.SET_DISCOUNT_CODE]({ discountCode });
        }
      })
      .catch((err) => {
        const message = "Failed to load convention data";
        logError(message, err);
        this.loading = false;
        this.notifyError(message);
      });
  },
};
</script>

<style scoped>
.container {
  padding: 0 10px;
}
</style>