import actions from "@/utils/constants/action_types";
import mutations from "@/utils/constants/mutation_types";

const scopedActions = actions.volunteer;
const scopedMutations = mutations.volunteer;

const state = () => ({
    isVolunteer: true,
    registrationType: '',
    firstName: '',
    lastName: '',
    tshirtSize: '',
    roomComments: '',
    contactInfo: {
        emailAddress: '',
        primaryContactNumber: '',
        secondaryContactNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
    },
    emergencyInfo: {
        emergencyContact: '',
        emergencyPhone: ''
    },
    priestId: '',
    church: '',
    donationAmount: 0,
    isTransportationNeeded: false,
    discountCode: null,
});

const getterHandlers = {};

const actionHandlers = {
    [scopedActions.SET_DISCOUNT_CODE]({ commit }, payload) {
        commit(scopedMutations.DISCOUNT_CODE_CHANGED, payload);
    },

    [scopedActions.UPDATE_FORM_FIELD]({ commit }, payload) {
        commit(scopedMutations.FORM_FIELD_CHANGED, payload);
    },
};

const mutationHandlers = {
    [scopedMutations.DISCOUNT_CODE_CHANGED](state, payload) {
        const { discountCode } = payload;
        state.discountCode = discountCode;
    },

    [scopedMutations.FORM_FIELD_CHANGED](state, payload) {
        const { prop, value, parentProp } = payload;
        if (parentProp) {
            state[parentProp][prop] = value;
        } else {
            state[prop] = value;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters: getterHandlers,
    actions: actionHandlers,
    mutations: mutationHandlers
}