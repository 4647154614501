import { USStates, CanadianProvinces, RoomPreferences, USChurches, CanadianChurches, OtherChurches } from "@/utils/constants";

const required = true;

const config = {
    contactInfoStep: {
        title: "Contact Info",
        sections: [
            {
                title: "Contact Info",
                parentField: "contactInfo",
                fields: [
                    {
                        name: "emailAddress",
                        type: "email",
                        required,
                        placeholder: "example@example.com",
                    },
                    {
                        name: "primaryContactNumber",
                        type: "phone",
                        required,
                        placeholder: "555-555-5555",
                    },
                    {
                        name: "secondaryContactNumber",
                        type: "phone",
                        placeholder: "555-555-5555",
                    },
                    {
                        name: "addressLine1",
                        type: "text",
                        required
                    },
                    {
                        name: "addressLine2",
                        type: "text",
                    },
                    {
                        name: "city",
                        type: "text",
                        required
                    },
                    {
                        name: "state",
                        label: "State / Province",
                        type: "select",
                        required,
                        optionList: [
                            {
                                group: "United States",
                                items: USStates
                            },
                            {
                                group: "Canada",
                                items: CanadianProvinces
                            },
                        ]
                    },
                    {
                        name: "zipCode",
                        type: "number",
                        placeholder: "12345",
                        required
                    },
                ]
            },
            {
                title: "Emergency Info",
                parentField: "emergencyInfo",
                fields: [
                    {
                        name: "emergencyContact",
                        type: "text",
                        required,
                        placeholder: "First Last",
                    },
                    {
                        name: "emergencyPhone",
                        type: "phone",
                        required,
                        placeholder: "555-555-5555",
                    },
                ]
            },
        ]
    },
    roomStep: {
        title: "Room",
        sections: [
            {
                title: "Room Selection",
                parentField: "room",
                fields: [
                    {
                        name: "roomCount",
                        label: "Rooms Needed",
                        type: "counter",
                        min: 1,
                        max: 2,
                        required,
                    },
                    {
                        name: "locationPreference",
                        label: "Room Preferences",
                        type: "multi-select",
                        infoText: "The convention facility is a two story building with NO elevator.\
                                    Room preference elections are not guaranteed. However, the convention committee \
                                    will do its best to accommodate all requests.",
                        optionList: RoomPreferences,
                    },
                    {
                        name: "roomComments",
                        type: "textarea"
                    }
                ]
            }
        ]
    },
    additionalInfoStep: {
        title: "Additional Info",
        sections: [
            {
                title: "Church",
                fields: [
                    {
                        name: "church",
                        type: "select",
                        required,
                        optionList: [
                            {
                                group: "United States",
                                items: USChurches
                            },
                            {
                                group: "Canada",
                                items: CanadianChurches
                            },
                            {
                                group: "Other",
                                items: OtherChurches
                            },
                        ]
                    },
                ]
            },
            {
                title: "Donation",
                fields: [
                    {
                        name: "donationAmount",
                        type: "counter",
                        min: 0,
                        step: 1,
                        editable: true,
                        infoText: "If you would like to make a donation with your registration, please enter an amount below.",
                    },
                ]
            },
            {
                title: "Transportation",
                fields: [
                    {
                        name: "isTransportationNeeded",
                        label: "I need aiport transportation",
                        type: "switch",
                        infoText: "After registering, please send your information to info@copticangel.org to arrange transportation.",
                    },
                ]
            }
        ]
    }
};

export default config;