<template>
  <div class="card has-background-info-light block">
    <div class="card-content">
      <p class="title is-5">
        <router-link
          :to="{
            name: 'admin-event-details',
            params: { eventId: convention.id },
          }"
          class="has-text-info-dark"
          >{{ convention.name }} >
        </router-link>
      </p>
      <div class="is-flex">
        <strong class="data-label">Start Date:</strong>
        <span>{{ startDate }}</span>
      </div>
      <div class="is-flex">
        <strong class="data-label">End Date:</strong>
        <span>{{ endDate }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatFullDate } from "@/utils/dateandtime";

export default {
  name: "AdminEventListItem",

  props: {
    convention: Object,
  },

  computed: {
    startDate() {
      return formatFullDate(this.convention.startDate);
    },

    endDate() {
      return formatFullDate(this.convention.endDate);
    },
  },
};
</script>

<style scoped>
.data-label {
  flex-basis: 100px;
  /* text-align: right; */
  margin-right: 10px;
}
</style>