<template>
  <section class="hero is-primary">
    <div class="hero-body">
      <div class="container header-content">
        
        <article class="media">
          <figure class="media-left">
            <img
              class="image is-64x64 is-rounded"
              src="@/assets/ArchangelRaphaelLogo.jpg"
              alt="Archangel Raphael"
            />
          </figure>
          <div class="media-content">
            <p class="title is-4">
              {{ title }}
            </p>
          </div>
        </article>
        
        <div class="is-flex mt-2">
          <div class="spacer is-hidden-mobile"></div>
          <b-icon icon="map-marker" />
          <span class="ml-1">{{ location }}</span>
        </div>
        <div class="is-flex mt-1">
          <div class="spacer is-hidden-mobile"></div>
          <b-icon icon="calendar-range" />
          <strong class="ml-1">{{ dates }}</strong>
        </div>
      
      </div>
    </div>
  </section>
</template>

<script>
import get from "lodash/get";
import { formatDay } from "@/utils/dateandtime";

export default {
  name: "HeaderView",

  props: {
    selectedConvention: {
      type: Object,
    },
  },

  computed: {
    title() {
      return get(this.selectedConvention, "name", "");
    },

    location() {
      return get(this.selectedConvention, "location", "");
    },

    dates() {
      const start = get(this.selectedConvention, "startDate");
      const end = get(this.selectedConvention, "endDate");

      if (!start || !end) {
        return "";
      }

      return `${formatDay(start)} - ${formatDay(end)}`;
    },
  },
};
</script>

<style scoped>
.spacer {
  width: 64px;
  margin-right: 1rem;
}
.header-content {
  max-width: 800px !important;
  margin: auto;
}
</style>