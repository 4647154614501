<template>
  <div class="block">
    <b-message v-if="field.infoText" type="is-info" size="is-small">{{
      field.infoText
    }}</b-message>
    <b-field :type="fieldError ? 'is-danger' : ''" :message="fieldError">
      <template #label>
        {{ fieldLabel }}
        <span v-if="field.required" class="has-text-danger">*</span>
      </template>

      <b-numberinput
        v-if="field.type == 'counter'"
        controls-rounded
        :editable="!!field.editable"
        :min="field.min"
        :max="field.max"
        :step="field.step || 1"
        :value="value"
        @input="updateFieldValue"
      ></b-numberinput>

      <select-form-field
        v-else-if="field.type == 'select'"
        :field="field"
        :value="value || ''"
        @input="updateFieldValue"
      ></select-form-field>

      <b-taginput
        v-else-if="field.type == 'multi-select'"
        ellipsis
        open-on-focus
        placeholder="Select one or more options"
        :data="field.optionList"
        :value="value"
        @input="updateFieldValue"
      >
      </b-taginput>

      <b-switch
        v-else-if="field.type == 'switch'"
        :value="value"
        @input="updateFieldValue"
      >
      </b-switch>

      <b-datepicker
        v-else-if="field.type == 'date'"
        placeholder="Click to select"
        icon="calendar-today"
        trap-focus
        :max-date="field.disableFutureDates ? new Date() : null"
        :value="value"
        @input="updateFieldValue"
      >
      </b-datepicker>

      <b-input
        v-else
        ref="fieldInput"
        :type="inputType"
        :value="value"
        :required="field.required"
        :placeholder="field.placeholder"
        :use-html5-validation="false"
        :icon="fieldIcon"
        @keyup.enter.native="$emit('keyup-enter')"
        @input="updateFieldValue"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
import startCase from "lodash/startCase";

import SelectFormField from "./SelectFormField.vue";

export default {
  name: "FormField",

  components: { SelectFormField },

  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Object, Array, Boolean, Date],
    },
    fieldError: {
      type: [String, Object],
    },
  },

  computed: {
    fieldLabel() {
      return this.field.label || startCase(this.field.name);
    },

    inputType() {
      let type = this.field.type;
      if (this.field.type === "phone") {
        type = "text";
      }
      return type;
    },

    fieldIcon() {
      let icon = null;
      if (this.field.type === "email") {
        icon = "email";
      }
      if (this.field.type === "phone") {
        icon = "plus-one";
      }
      return icon;
    },
  },

  methods: {
    updateFieldValue(value) {
      if (value instanceof Date && this.field.type == 'date') {
        value.setHours(12);
      }
      this.$emit("input", value);
    },
  },
};
</script>